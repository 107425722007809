import React, { useState } from 'react';
import { Grid, Embed, Container, NavLink, Text } from 'theme-ui';
/*
  Use this section to highlight key elements of your site. Some sites will only have two while others may have six to eight. 
*/

const Highlights = () => {
  const tabHighlight = {
    'text-decoration-line': 'underline',
    'text-decoration-thickness': '2px',
    'font-size': '14px',
    'text-underline-offset': '6px',
  };

  const tabGreyed = {
    'font-size': '14px',
    color: '#666666',
    'font-weight': '',
  };

  const ei = {
    diversion: 'https://datawrapper.dwcdn.net/rHn7O/9/',
    deferred_prosectution: 'https://datawrapper.dwcdn.net/qy4Pg/8/',
  };
  const dismiss = {
    felony: 'https://datawrapper.dwcdn.net/vNa6x/',
    misdemeanor: 'https://datawrapper.dwcdn.net/dGfON/',
  };

  //iframe state
  const [diversionIframe, setDiversionTab] = useState(ei['diversion']);
  const [dismissIframe, setDismissIframeTab] = useState(dismiss['felony']);

  const [divTab, setDivTab] = useState([tabHighlight, tabGreyed]);
  const [dismissTab, setDismissTab] = useState([tabHighlight, tabGreyed]);

  function handleLDivTabClick() {
    setDiversionTab(ei['diversion']);
    setDivTab([tabHighlight, tabGreyed]);
  }

  function handleRDivTabClick() {
    setDiversionTab(ei['deferred_prosectution']);
    setDivTab([tabGreyed, tabHighlight]);
  }

  function handleLDismissTabClick() {
    setDismissIframeTab(dismiss['felony']);
    setDismissTab([tabHighlight, tabGreyed]);
  }

  function handleRDismissTabClick() {
    setDismissIframeTab(dismiss['misdemeanor']);
    setDismissTab([tabGreyed, tabHighlight]);
  }

  return (
    <section className="usa-graphic-list usa-section">
      <div className="grid-container">
        <div class="border-top-1 border-accent-cool-darker padding-top-2 margin-bottom-4 usa-prose">
          <h1 className="text-bold margin-0">
            Milwaukee District Attorney Key Indicators
          </h1>
        </div>
        <span className="text-base-lighter display-block padding-top-3 text-light"></span>
      </div>

      <div className="grid-container">
        <Grid gap={0} columns={[2, '1fr']} width={[350]}>
          <Container>
            <NavLink
              sx={{ cursor: 'pointer' }}
              p={0}
              onClick={handleLDivTabClick}
            >
              <Text sx={divTab[0]}>Diversion </Text>
            </NavLink>
            <NavLink
              sx={{ cursor: 'pointer' }}
              p={2}
              onClick={handleRDivTabClick}
            >
              <Text sx={divTab[1]}>Deferred Prosecution</Text>
            </NavLink>
            <Embed
              sx={{
                height: '400px',
                width: '1fr',
              }}
              src={diversionIframe}
            />{' '}
          </Container>

          <Container>
            <NavLink
              sx={{ cursor: 'pointer' }}
              p={0}
              onClick={handleLDismissTabClick}
            >
              <Text sx={dismissTab[0]}>Felony </Text>
            </NavLink>
            <NavLink
              sx={{ cursor: 'pointer' }}
              p={2}
              onClick={handleRDismissTabClick}
            >
              <Text sx={dismissTab[1]}>Misdemeanor</Text>
            </NavLink>

            <Embed
              sx={{
                height: '400px',
                width: '1fr',
              }}
              src={dismissIframe}
            />
          </Container>
          <Embed
            sx={{
              height: '400px',
              width: '1fr',
            }}
            src="https://datawrapper.dwcdn.net/ngPYG/8/"
          />
          <Embed
            sx={{
              height: '400px',
              width: '1fr',
            }}
            src="https://datawrapper.dwcdn.net/9gWii/5/"
          />
        </Grid>
      </div>
    </section>
  );
};
export default Highlights;
