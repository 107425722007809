import React from 'react';

/*
  This tagline will appear in your homepage
*/

const Tagline = () => (
  <section className="grid-container usa-section usa-prose margin-bottom-0 ">

    <div className="grid-row grid-gap">
      <div className="tablet:grid-col-4">
        <h2 className="font-heading-xl margin-top-0 tablet:margin-bottom-0">
          Data is key to meaningful and sustainable justice reform in Milwaukee.
        </h2>
      </div>
      <div className="tablet:grid-col-8 usa-prose">
        <p> The Milwaukee County District Attorney's Office is a longtime proponent of sharing data with communities and justice system agencies to improve fairness and effectiveness. The MCDA is committed to transparency, accountability, and data-driven reform in the Milwaukee County Justice System.
        </p>
        <p>
        To that end, key indicators on how the justice system functions are released monthly from referral and arrest to sentencing. This is an ongoing commitment, the data displayed on this site is just the first of many data points that we intend to release to the public.
        This dashboard is developed in partnership with the <a style={{color:"#07648d"}} href='https://prosecutorialperformanceindicators.org/'>Prosecutorial Performance Indicators.</a>
          </p>
      </div>
    </div>

  </section>
);

export default Tagline;