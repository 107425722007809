import React from 'react';
import { Link } from 'gatsby';
/*
  This will be displayed on the homepage. Ideally, you want to highlight key goals of the website
*/

const Hero = () => (
  <section className="usa-hero">
    <div className="grid-container">
      <div className="usa-hero__callout">
        <h2 className="usa-hero__heading">
          <span className="usa-hero__heading--alt">Opening Milwaukee County District Attorney Data to the Public </span>
        </h2>
        <Link to="/about" title="About" aria-label="About">
          <div className="usa-button usa-button--secondary" style={{marginTop: "14px"}}> Learn More </div></Link>



        
      </div>
    </div>
  </section>
);

export default Hero;

